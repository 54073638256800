import { useCallback, useState } from 'react';
import { useLocation } from 'react-use';

import { useFetch } from '#root/hooks/use-fetch';

export type OrderBy = [string, 'asc' | 'desc'] | null;
type Params = { [key: string]: boolean | string | number };

export type Pager<T extends {}> = ReturnType<typeof usePager<T>>;
export interface IUsePagerOptions {
  url: string;
  parentParams?: Params;
  filterable?: string[];
  defaultSort?: OrderBy;
  rowsPerPage?: number;
  enabled?: boolean;
}
export function usePager<T extends {}>({
  url,
  parentParams = {},
  filterable = [],
  defaultSort = null,
  rowsPerPage = 200,
  enabled = true,
}: IUsePagerOptions) {
  const location = useLocation();
  const filters = (location?.search ?? '')
    .substring(1)
    .split('&')
    .map(search => search.split('='))
    .filter(filter => filterable.includes(filter[0]));
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState(defaultSort ? defaultSort : null);

  const params: Params = {
    ...parentParams,
    limit: rowsPerPage,
    filters: filters.map(filter => filter.join('|')).join(';'),
  };

  if (page > 0) {
    params.skip = page * rowsPerPage;
  }

  if (orderBy) {
    params.sort_field = orderBy[0];
    params.sort_dir = orderBy[1];
  }

  const {
    data,
    setData,
    loading,
    load: refresh,
  } = useFetch<T[] | undefined>(url, undefined, params, enabled);

  const handleChangePage = useCallback((next: number) => setPage(next), []);
  const next = useCallback(() => setPage(prev => prev + 1), []);
  const previous = useCallback(() => setPage(prev => Math.max(0, prev - 1)), []);

  const onOrderByChangeCallback = useCallback((type: string) => {
    setOrderBy(prev => {
      if (!prev) {
        return [type, 'asc'];
      }

      return prev[0] !== type ? [type, prev[1]] : [type, prev[1] === 'asc' ? 'desc' : 'asc'];
    });
  }, []);

  const firstPage = page === 0;
  const lastPage = !data || data.length < rowsPerPage;

  return {
    data,
    setData,
    loading,
    refresh,
    handleChangePage,
    next,
    previous,
    onOrderByChangeCallback,
    page,
    firstPage,
    lastPage,
    orderBy,
  };
}
