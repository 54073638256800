import pLimit from 'p-limit';

import { BaseService } from '../base-service';
import { IEditablePhoto, IParty, IPhoto, IPublicContact } from '../types';

import { api } from '#root/utils/api';
const limit = pLimit(3);

export class PhotosService extends BaseService {
  private abortController?: AbortController;

  public async upload(
    partyId: IParty['id'],
    photos: File[],
    name: string | undefined,
    currentlyLookingContact: IPublicContact | undefined | null,
    onUpdate: (total: number, done: number, failed: number, photo?: IEditablePhoto) => void
  ): Promise<IEditablePhoto[]> {
    if (!this.abortController) {
      this.abortController = new AbortController();
    }

    const status = {
      total: photos.length,
      done: 0,
      failed: 0,
    };
    onUpdate(status.total, status.done, status.failed);
    // const uploadedPhotos = await promiseAllInBatches(
    //   async photo => {
    //     const data = new FormData();
    //     data.append('name', name ?? '');
    //     data.append('photo', photo);
    //     try {
    //       const uploadedPhoto = await api
    //         .post(`/api/party/${partyId}/photo`, { body: data, timeout: false })
    //         .json<IPhoto>();
    //       status.done++;
    //       return uploadedPhoto;
    //     } catch (e) {
    //       status.failed++;
    //     } finally {
    //       onUpdate(status.total, status.done, status.failed);
    //     }
    //   },
    //   photos,
    //   5
    // );
    const uploadedPhotos = await Promise.all(
      photos.map(photo =>
        limit(async () => {
          if (!this.abortController) {
            return;
          }

          const { signal } = this.abortController;

          const data = new FormData();
          data.append('contact', currentlyLookingContact?.id ?? '');
          data.append('name', name ?? '');
          data.append('photo', photo);
          try {
            const uploadedPhoto = await api
              .post(`/api/party/${partyId}/photo`, { body: data, timeout: false, signal })
              .json<IEditablePhoto>();
            status.done++;
            onUpdate(status.total, status.done, status.failed, uploadedPhoto);
            return uploadedPhoto;
          } catch (e) {
            status.failed++;
            onUpdate(status.total, status.done, status.failed);
          }
        })
      )
    );
    // const uploadedPhotos = await Promise.all(
    //   photos.map(async photo => {
    //     const data = new FormData();
    //     data.append('name', name ?? '');
    //     data.append('photo', photo);
    //     try {
    //       const uploadedPhoto = await api
    //         .post(`/api/party/${partyId}/photo`, { body: data, timeout: false })
    //         .json<IPhoto>();
    //       status.done++;
    //       return uploadedPhoto;
    //     } catch (e) {
    //       status.failed++;
    //     } finally {
    //       onUpdate(status.total, status.done, status.failed);
    //     }
    //   })
    // );
    console.log({ uploadedPhotos });

    return uploadedPhotos.filter(photo => !!photo) as IEditablePhoto[];
  }

  public delete(partyId: IParty['id'], photoId: IPhoto['id']) {
    return api.delete(`/api/party/${partyId}/photo/${photoId}`);
  }

  public cancelUploads() {
    this.abortController?.abort();
    this.abortController = undefined;
  }

  public changePublic(partyId: IParty['id'], photoId: IPhoto['id'], setPublic: boolean): void {
    api.patch(`/api/party/${partyId}/photo/${photoId}`, { json: { public: setPublic } });
  }
}
