import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { IPublicContact } from '../types';

export interface ContactState {
  currentlyViewingContact?: IPublicContact | null;
}

const initialState: ContactState = {
  currentlyViewingContact: undefined,
};

export const contactSlice = createSlice({
  name: 'contact',
  reducers: {
    updateCurrentlyViewingContact: (state, action: PayloadAction<{ contact: IPublicContact }>) => {
      const { contact } = action.payload;
      state.currentlyViewingContact = contact;
    },
  },
  initialState,
});
