import { IEditableParty, IParty } from './types';

import type { PartyTypes } from '#root/Components/PartyWizard/Types';

export interface IParameters {
  party?: IParty | IEditableParty;
  defaultCreateType?: PartyTypes;
  connectSuccess?: boolean;
  isUnsubscribed?: boolean;
  hash?: string;
  signature?: string;
  errorUrl?: string;
  payment?:
    | false
    | {
        type: 'plan' | 'token';
        orderId: string;
        conversionValue: number;
        conversionCurrency: string;
        conversionId: string;
      };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parameters = ((window as any).parameters ?? {}) as IParameters;
