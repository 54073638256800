import { useParams } from 'react-router-dom';

import { useStoreState } from '#root/store';
import { idParamIsParty } from '#root/utils/idParamIsParty';

export const useLanguage = () => {
  const { idOrLang } = useParams<{ idOrLang: string }>();

  const userLanguage = useStoreState(state => state.app.currentUser?.language);
  const partyLanguage = useStoreState(state => state.party.party?.language);

  const lang = idOrLang && !idParamIsParty(idOrLang) ? idOrLang : partyLanguage ?? userLanguage;

  return lang ?? 'da';
};
