import { Store } from '@reduxjs/toolkit';

import { IStoreState, ServiceGetter, store } from '.';

export abstract class BaseService {
  protected store: Store<IStoreState>;
  constructor(protected serviceGetter: ServiceGetter) {
    this.store = store;
  }
}
