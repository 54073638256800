import { IconButton } from '@mui/material';
import React, { FC, Fragment, ReactNode } from 'react';

import { Loading } from '../Loading';

import { Refresh } from './Refresh';
import s from './index.module.scss';

import { Pager } from '#root/hooks/use-pager';
import { ArrowLeft, ArrowRight } from '#root/utils/icons';

type OrderType = 'refresh' | 'loading' | 'next' | 'prev';
export interface IProps<T extends Pager<any>> {
  pager: T;
  actions?: (pager: T) => ReactNode;
  canRefresh?: boolean;
  order?: OrderType[];
  className?: string;
}

export const PagerActions: FC<IProps<Pager<any>>> = ({
  pager,
  actions,
  canRefresh,
  order = ['prev', 'next', 'refresh', 'actions', 'loading'],
  className,
}) => {
  const slots = {
    prev: pager.page > 0 && (
      <IconButton onClick={() => pager.handleChangePage(pager.page - 1)} disabled={pager.loading}>
        <ArrowLeft />
      </IconButton>
    ),
    next: !pager.lastPage && (
      <IconButton
        onClick={() => pager.handleChangePage(pager.page + 1)}
        disabled={pager.loading || pager.lastPage}
      >
        <ArrowRight />
      </IconButton>
    ),
    refresh: canRefresh && <Refresh onClick={pager.refresh} loading={pager.loading} />,
    actions: actions && !(pager.loading || pager.data === undefined) && actions(pager),
    loading:
      pager.loading && pager.data !== undefined ? (
        <span className={s.loader}>
          <Loading size="small" />
        </span>
      ) : null,
  };

  const shownSlots = order
    .filter(slot => slots[slot] !== null)
    .map(slot => <Fragment key={slot}>{slots[slot]}</Fragment>);

  return shownSlots ? <div className={className}>{shownSlots}</div> : null;
};
