import clsx from 'clsx';
import React, { FC } from 'react';

import s from './index.module.scss';

type TdProps = React.ComponentPropsWithoutRef<'div'>;

export interface ICellProps extends TdProps {
  fullWidth?: boolean;
  stackOnMobile?: boolean;
  textWrap?: boolean;
  condense?: boolean;
}

export const Cell: FC<ICellProps> = ({
  children,
  className,
  fullWidth,
  textWrap,
  condense,
  stackOnMobile,
  ...tdProps
}) => (
  <div
    className={clsx(
      s.cell,
      stackOnMobile && s.stackOnMobile,
      fullWidth && s.cellFullWidth,
      textWrap && s.cellTextWrap,
      condense && s.cellCondense,
      className
    )}
    {...tdProps}
  >
    {children}
  </div>
);
