import { BaseService } from '../base-service';
import { partySlice } from '../party/reducer';
import { IMessageTemplate, IParty } from '../types';

import { api } from '#root/utils/api';

export class MessageTemplatesService extends BaseService {
  public async save(
    partyId: IParty['id'],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { id, createdAt, modifiedAt, ...template }: IMessageTemplate
  ) {
    const apiFunc = id.startsWith('new-') ? api.post : api.patch;
    const url = id.startsWith('new-')
      ? `/api/party/${partyId}/template`
      : `/api/party/${partyId}/template/${id}`;

    const responseTemplate = await apiFunc(url, {
      json: { template },
    }).json<IMessageTemplate>();

    this.store.dispatch(partySlice.actions.updateTemplate({ template: responseTemplate }));
    return responseTemplate;
  }
  public async delete(partyId: IParty['id'], templateId: IMessageTemplate['id']) {
    await api.delete(`/api/party/${partyId}/template/${templateId}`);
    this.store.dispatch(partySlice.actions.deleteTemplate({ templateId }));
  }
}
