import { FC, PropsWithChildren, ReactNode } from 'react';

export interface IProps {
  condition: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  wrapper: (children: ReactNode | undefined) => any;
}

export const ConditionalWrapper: FC<PropsWithChildren<IProps>> = ({
  condition,
  wrapper,
  children,
}) => (condition ? wrapper(children) : children);
