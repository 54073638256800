import { BaseService } from '../base-service';
import { IContact, IParty } from '../types';

import { api } from '#root/utils/api';
export class ContactService extends BaseService {
  public async save(partyId: IParty['id'], data: IContact): Promise<IContact> {
    if (data.id) {
      return api.patch(`/api/party/${partyId}/contact/${data.id}`, { json: data }).json<IContact>();
    }
    return api.post(`/api/party/${partyId}/contact`, { json: data }).json<IContact>();
  }
  public async import(
    partyId: IParty['id'],
    csvInput: string,
    deleteOnImport: boolean
  ): Promise<{ created: number; missing: string; errors: number }> {
    return api
      .post(`/api/party/${partyId}/contact/import`, {
        json: { contacts: csvInput, deleteOnImport },
      })
      .json<{ created: number; missing: string; errors: number }>();
  }
  public delete(partyId: IParty['id'], contactId: IContact['id']) {
    return api.delete(`/api/party/${partyId}/contact/${contactId}`);
  }
}
