import { useCallback } from 'react';
import { NavigateOptions, useNavigate as useOrigNavigate } from 'react-router-dom';

import { useLanguage } from './use-language';

export const useNavigate = () => {
  const lang = useLanguage();
  const origNavigate = useOrigNavigate();

  const navigate = useCallback(
    (path: string, direct = false, options?: NavigateOptions) => {
      return !direct ? origNavigate(`/${lang}${path}`, options) : origNavigate(path, options);
    },
    [lang]
  );

  return navigate;
};
