export const stripDomain = (url: string) => {
  return url.replace(/^https?:\/\/(www\.)?/, '').replace(/\/$/, '');
};
export const truncate = (str: string, chars: number, ellipsis = '…') => {
  return str.length > chars ? str.slice(0, chars - 1) + ellipsis : str;
};

export const safeRegex = (text: string) => text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
export const replaceAll = (text: string, search: string, replace: string) =>
  text.replace(new RegExp(safeRegex(search), 'g'), replace);
export const substituteVariabels = (
  text: string,
  variables: { [key: string | symbol]: string | number | undefined } = {}
) => {
  let substitutedText = text;
  for (const key of Object.keys(variables)) {
    const value = variables[key];
    if (value === undefined) {
      substitutedText = removePlaceholder(substitutedText, key);
    } else {
      substitutedText = replaceAll(substitutedText, `\[\?${key}\]`, '');
      substitutedText = replaceAll(substitutedText, `\[${key}\?\]`, '');
      substitutedText = replaceAll(substitutedText, `\[${key}\]`, value.toString());
    }
  }
  return substitutedText;
};
export const substituteVariabelsInObject = (
  object: { [key: string | symbol]: string },
  variables: { [key: string | symbol]: string | number | undefined } = {}
) => {
  const returnObject: { [key: string]: string } = {};
  for (const key of Object.keys(object)) {
    returnObject[key] = substituteVariabels(object[key], variables);
  }
  return returnObject;
};
export const removePlaceholder = (text: string, segment: string): string => {
  const regex = `\\[\\?${segment}\\].+\\[${segment}\\?\\]`;
  return text.replace(new RegExp(regex, 'g'), '');
};
export const nameSuffixS = (name: string) => {
  name = name.trim();
  const endsOnS = name.endsWith('s');
  return name + (endsOnS ? "'" : 's');
};

export const formatCurrency = (amount: number, currency: 'eur' | 'dkk', simple = false) => {
  const digits = (amount / 100).toFixed(2).replace('.00', '');

  switch (currency) {
    case 'eur':
      return simple ? `${digits}` : `€ ${digits}`;

    case 'dkk':
      return simple ? `${digits},-` : `DKK ${digits},-`;
  }
};

export const emojiRegex =
  /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/g;
// /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu;
