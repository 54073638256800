import clsx from 'clsx';
import React, { FC, PropsWithChildren } from 'react';

import s from './index.module.scss';

export interface IFlexProps {
  className?: string;
  direction?: 'column' | 'row';
  reverse?: boolean;
  column?: boolean;
  justify?: 'center' | 'end' | 'space-between' | 'space-evenly' | 'space-around';
  align?: 'center' | 'end';
  gap?: boolean | 'half';
  stackOnMobile?: boolean;
  bordered?: boolean;
  outlined?: boolean;
}

type DivProps = React.ComponentPropsWithoutRef<'div'>;

export const Flex: FC<DivProps & IFlexProps & IFlexItemProps & PropsWithChildren> = ({
  children,
  className,
  direction = 'row',
  reverse,
  column,
  justify,
  align,
  gap,
  stackOnMobile,
  bordered,
  outlined,
  pushRight,
  grow,
  ...rest
}) => {
  return (
    <div
      className={clsx(
        s.flex,
        className,
        (direction === 'column' || column) && s.directionColumn,
        reverse && s.directionReverse,
        justify === 'center' && s.justifyCenter,
        justify === 'end' && s.justifyEnd,
        justify === 'space-around' && s.justifySpaceAround,
        justify === 'space-between' && s.justifySpaceBetween,
        justify === 'space-evenly' && s.justifySpaceEvenly,
        align === 'center' && s.alignCenter,
        align === 'end' && s.alignEnd,
        (gap === 'half' && s.gapHalf) || (gap && s.gap),
        bordered && s.bordered,
        outlined && s.outlined,
        stackOnMobile && s.stackOnMobile,
        pushRight && s.itemPushRight,
        grow && s.itemGrow
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

export interface IFlexItemProps {
  pushRight?: boolean;
  grow?: boolean;
  column?: boolean;
  className?: string;
}

export const Item: FC<DivProps & IFlexItemProps & PropsWithChildren> = ({
  children,
  pushRight,
  grow,
  column,
  className,
  ...rest
}) => {
  return (
    <div
      className={clsx(
        className,
        pushRight && s.itemPushRight,
        grow && s.itemGrow,
        column && s.itemColumn
      )}
      {...rest}
    >
      {children}
    </div>
  );
};
