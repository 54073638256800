import { AnyAction, combineReducers, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { appSlice } from './app/reducer';
import { AppService } from './app/service';
import { contactSlice } from './contact/reducer';
import { ContactService } from './contact/service';
import { MessageTemplatesService } from './messageTemplates/service';
import { messagesSlice } from './messages/reducer';
import { MessagesService } from './messages/service';
import { partySlice } from './party/reducer';
import { PartyService } from './party/service';
import { PhotosService } from './photos/service';
import { RsvpService } from './rsvp/service';
import { SettingsService } from './settings/service';
import { TranslationService } from './translation';
import { WishlistService } from './wishlist/service';

const reducers = combineReducers({
  app: appSlice.reducer,
  party: partySlice.reducer,
  contact: contactSlice.reducer,
  messages: messagesSlice.reducer,
});

export type IStoreState = ReturnType<typeof reducers>;

export const store = configureStore({ reducer: reducers });

export const useStoreState: TypedUseSelectorHook<IStoreState> = useSelector;

export type AsyncAction<F extends (...args: any) => any> = (
  ...commandArgs: Parameters<F>
) => (dispatch: ThunkDispatch<IStoreState, void, AnyAction>, state: IStoreState) => ReturnType<F>;

interface IServices {
  app: AppService;
  party: PartyService;
  translation: TranslationService;
  rsvp: RsvpService;
  contact: ContactService;
  settings: SettingsService;
  messageTemplates: MessageTemplatesService;
  messages: MessagesService;
  wishlist: WishlistService;
  photos: PhotosService;
}

type Constructor<T = any> = new (...args: any[]) => T;
const services: { [T in keyof IServices]: Constructor } = {
  app: AppService,
  party: PartyService,
  translation: TranslationService,
  rsvp: RsvpService,
  contact: ContactService,
  settings: SettingsService,
  messageTemplates: MessageTemplatesService,
  messages: MessagesService,
  wishlist: WishlistService,
  photos: PhotosService,
};

const serviceInstances: Partial<IServices> = {};

export const getService = <S extends keyof IServices>(type: S) => {
  if (typeof serviceInstances[type] === 'undefined') {
    serviceInstances[type] = new services[type]();
  }

  return serviceInstances[type] as IServices[typeof type];
};
(window as any).getService = getService;
export type ServiceGetter = typeof getService;
