import { useCallback, useEffect, useMemo, useState } from 'react';

import { api } from '../utils/api';

export const useFetch = <T>(
  path: string,
  defaultData: T,
  extraParams: { [key: string]: boolean | string | number } = {},
  enabled = true,
  baseUrl: string = window.location.toString(),
  clearOnRefetch = false
) => {
  const [data, setData] = useState<T>(defaultData);
  const [loading, setLoadingState] = useState(false);

  const parsedUrl = useMemo(() => {
    const url = new URL(path, baseUrl);

    for (const key in extraParams) {
      url.searchParams.append(key, extraParams[key].toString());
    }

    return url.toString();
  }, [path, baseUrl, extraParams]);

  const load = useCallback(() => {
    if (!enabled) return;
    setLoadingState(true);
    if (clearOnRefetch) {
      setData(defaultData);
    }
    api
      .get(parsedUrl)
      .json()
      .then(result => {
        setData(result as T);
        setLoadingState(false);
      })
      .catch(error => {
        console.log(error);
        setLoadingState(false);
      });
  }, [parsedUrl, enabled, clearOnRefetch]);

  useEffect(load, [parsedUrl, enabled, clearOnRefetch]);

  const returnObject = {
    data,
    setData,
    loading,
    load,
  };
  returnObject[0] = returnObject.data;
  returnObject[1] = returnObject.setData;
  returnObject[2] = returnObject.loading;
  returnObject[3] = returnObject.load;

  return returnObject;
};
