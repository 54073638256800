import { BaseService } from '../base-service';
import { IContact, IParty, IPublicRsvp, IRsvp, IRsvpFormData, IRsvpPublicFormData } from '../types';

import { api } from '#root/utils/api';

export class RsvpService extends BaseService {
  public async submit(
    partyId: IParty['id'],
    data: IRsvpPublicFormData & { contact?: IContact['id'] }
  ) {
    return api.post(`/api/party/${partyId}/rsvp`, { json: data }).json<IPublicRsvp>();
  }
  public async save(partyId: IParty['id'], data: IRsvpFormData) {
    if (data.id) {
      await api.patch(`/api/party/${partyId}/rsvp/${data.id}`, { json: data }).json<IRsvp>();
    } else {
      await api.post(`/api/party/${partyId}/rsvp`, { json: data }).json<IRsvp>();
    }
  }
  public async delete(partyId: IParty['id'], rsvpId: IRsvp['id']) {
    await api.delete(`/api/party/${partyId}/rsvp/${rsvpId}`);
  }
  public async reply(partyId: IParty['id'], rsvpId: IRsvp['id'], message: string) {
    return api
      .post(`/api/party/${partyId}/rsvp/${rsvpId}/message`, { json: { message } })
      .json<IPublicRsvp>();
  }
}
