import { IconButton } from '@mui/material';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { RefreshIcon } from '#root/utils/icons';

export interface IProps {
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
}

export const Refresh: FC<IProps> = ({ onClick, loading, disabled = false }) => {
  const [enabled, setEnabled] = useState(false);
  const timerRef = useRef<number>();

  const handle = useCallback(() => {
    onClick();
    setEnabled(false);
  }, [onClick]);

  useEffect(() => {
    if (loading && enabled) {
      setEnabled(false);
      if (timerRef.current) {
        window.clearTimeout(timerRef.current);
      }
    }
  }, [loading, enabled]);

  useEffect(() => {
    if (!enabled && !loading) {
      const timeout = window.setTimeout(() => {
        setEnabled(true);
      }, 4000);

      timerRef.current = timeout;

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [enabled, loading]);

  return (
    <IconButton onClick={handle} disabled={!enabled || disabled}>
      <RefreshIcon />
    </IconButton>
  );
};
