import {
  Button,
  ButtonProps,
  Dialog,
  DialogProps,
  DialogTitle,
  IconButton,
  IconButtonProps,
  MenuItem,
} from '@mui/material';
import React, { ReactNode, useCallback, useState } from 'react';

import { Flex } from './Flex';

import { CloseIcon } from '#root/utils/icons';

interface IProps extends Partial<Omit<DialogProps, 'children'>> {
  buttonText?: ReactNode;
  ButtonProps?: ButtonProps;
  icon?: ReactNode;
  menuItem?: ReactNode;
  IconButtonProps?: IconButtonProps;
  header?: ReactNode;
  children: (onToggleOpen: () => void) => ReactNode;
  withCloseButton?: boolean;
  onClose?: () => void;
  onClick?: () => void;
  disabled?: boolean;
}

export const ButtonDialog = ({
  buttonText,
  ButtonProps,
  icon,
  menuItem,
  IconButtonProps,
  header,
  children,
  withCloseButton,
  onClose,
  onClick,
  disabled,
  ...rest
}: IProps) => {
  const [open, setOpen] = useState(false);
  const onToggleOpen = useCallback(() => {
    onClick?.();
    setOpen(prev => !prev);
  }, [onClick]);
  const innerOnClose = () => {
    onToggleOpen();
    onClose?.();
  };

  return (
    <>
      {menuItem ? (
        <MenuItem onClick={onToggleOpen}>{menuItem}</MenuItem>
      ) : icon ? (
        <IconButton onClick={onToggleOpen} disabled={disabled} {...IconButtonProps}>
          {icon}
        </IconButton>
      ) : (
        <Button
          variant="contained"
          disableElevation
          onClick={onToggleOpen}
          disabled={disabled}
          {...ButtonProps}
        >
          {buttonText}
        </Button>
      )}
      <Dialog open={open} onClose={innerOnClose} aria-labelledby="dialog-title" fullWidth {...rest}>
        {header || withCloseButton ? (
          <DialogTitle id="dialog-title" style={withCloseButton ? { marginRight: 40 } : undefined}>
            <Flex>
              {header}
              {withCloseButton ? (
                <IconButton
                  aria-label="close"
                  onClick={innerOnClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              ) : null}
            </Flex>
          </DialogTitle>
        ) : null}
        {children(innerOnClose)}
      </Dialog>
    </>
  );
};
