import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useNavigate } from '#root/hooks/use-navigate';

export const useFilterLink = (name: string, path?: string, direct?: boolean) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = location.search;
  const active = new URLSearchParams(searchParams).get(name);
  const onFilter = useCallback(
    (value: string | null) => {
      const urlSearchParams = new URLSearchParams(searchParams);
      if (value === null) {
        urlSearchParams.delete(name);
      } else {
        urlSearchParams.set(name, value);
      }
      navigate((path ?? location.pathname) + '?' + urlSearchParams.toString(), direct);
    },
    [searchParams, name, path, direct]
  );

  return { onFilter, active };
};
