import { getService } from '..';
import { BaseService } from '../base-service';
import { IContact, IMessageTemplate, IParty, MessageToWhomType } from '../types';

import { messagesSlice } from './reducer';

import { api } from '#root/utils/api';

export class MessagesService extends BaseService {
  public async send(
    partyId: IParty['id'],
    templateId: IMessageTemplate['id'],
    toWhom: MessageToWhomType,
    omitSms = false
  ) {
    let contacts = {};
    if (toWhom === 'custom') {
      contacts = Object.keys(this.store.getState().messages.manualSelection);
    }
    const response = await api
      .post(
        `/api/party/${partyId}/messages/send/${templateId}/${toWhom}${omitSms ? '?omit-sms' : ''}`,
        {
          json: contacts,
        }
      )
      .json<{
        tokensUsed: number;
        emailsSent: number;
        textSent: number;
        errors: number;
        contacts: IContact[];
      }>();

    getService('party').refetch();

    return response;
  }
  public async test(
    partyId: IParty['id'],
    templateId: IMessageTemplate['id'],
    changedData: Partial<IMessageTemplate>
  ) {
    return api.post(`/api/party/${partyId}/messages/test/${templateId}`, { json: changedData });
  }
  public check(contact: IContact) {
    this.store.dispatch(messagesSlice.actions.check({ contact }));
  }
  public uncheck(contactId: IContact['id']) {
    this.store.dispatch(messagesSlice.actions.uncheck({ id: contactId }));
  }
  public clearManualSelection() {
    this.store.dispatch(messagesSlice.actions.clearManualselection());
  }
  public async preview(
    partyId: IParty['id'],
    templateId: IMessageTemplate['id'],
    changedData: Partial<IMessageTemplate>
  ): Promise<string> {
    return await api
      .post(`/api/party/${partyId}/messages/preview/${templateId}`, { json: changedData })
      .text();
  }
}
