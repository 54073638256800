import { Button } from '@mui/material';
import { FallbackRender, ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import React, { FC, PropsWithChildren } from 'react';

import { Flex } from '../Flex';
import { Logo } from '../Logo';

import { ThemeProvider } from '#root/Entry/inviime/ThemeProvider';
import { css } from '#root/utils/css';

const FallbackComponent: FallbackRender = ({ eventId }) => (
  <ThemeProvider>
    <Flex
      column
      gap
      align="center"
      justify="center"
      style={{ height: '100vh' }}
      className={css.spaceL}
    >
      <Logo />
      <p>
        An error occurred, please reload the page and try again. If this error persists please{' '}
        <a href={`mailto:contact@invii.me?subject=Error [${eventId}]`} title="contact@invii.me">
          contact us to let us know
        </a>
        .
      </p>
      <Button disableElevation variant="contained" onClick={() => window.location.reload()}>
        Refresh
      </Button>
      <span style={{ color: 'var(--color-grey)', fontSize: '0.6em' }}>{eventId}</span>
    </Flex>
  </ThemeProvider>
);
export const ErrorBoundary: FC<PropsWithChildren> = ({ children }) => {
  return <SentryErrorBoundary fallback={FallbackComponent}>{children}</SentryErrorBoundary>;
};
