import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  type IEditableParty,
  IMessageTemplate,
  type IParty,
  IPublicContact,
  IPublicRsvp,
  isEditableParty,
} from '../types';

export interface PartyState {
  isInititalized: boolean;
  party?: IEditableParty | IParty;
  originalParty?: IEditableParty;
  modifiedFields: Partial<IEditableParty>;
  canEdit: boolean;
  isDirty: boolean;
  editMode: boolean;
  currentlyViewingContact?: IPublicContact | null;
  currentlyViewingRsvp?: IPublicRsvp | null;
  globalLoading: boolean;
}

const initialState: PartyState = {
  isInititalized: false,
  party: undefined,
  originalParty: undefined,
  modifiedFields: {},
  canEdit: false,
  editMode: false,
  isDirty: false,
  currentlyViewingContact: undefined,
  currentlyViewingRsvp: undefined,
  globalLoading: false,
};

export const partySlice = createSlice({
  name: 'party',
  reducers: {
    clearParty: () => {
      return initialState;
    },
    updateParty: (state, action: PayloadAction<{ party: IEditableParty | IParty }>) => {
      const { party } = action.payload;
      state.party = party;
      if (isEditableParty(party)) {
        state.originalParty = { ...party };
        state.canEdit = true;
      } else {
        delete state.originalParty;
        state.canEdit = false;
      }
      state.modifiedFields = {};
      state.isDirty = false;
      state.editMode = false;
    },
    updateTemplate: (state, action: PayloadAction<{ template: IMessageTemplate }>) => {
      const { template } = action.payload;
      if (state.originalParty) {
        const index = state.originalParty.messageTemplates.findIndex(t => t.id === template.id);
        if (index > -1) {
          state.originalParty.messageTemplates[index] = template;
          // state.originalParty.messageTemplates = state.originalParty.messageTemplates.map(t =>
          //   t.id === template.id ? template : t
          // );
        } else {
          state.originalParty.messageTemplates.push(template);
        }
      }
    },
    deleteTemplate: (state, action: PayloadAction<{ templateId: IMessageTemplate['id'] }>) => {
      const { templateId } = action.payload;
      if (state.originalParty) {
        state.originalParty.messageTemplates = state.originalParty.messageTemplates.filter(
          template => template.id !== templateId
        );
      }
    },
    updateField: (state, action: PayloadAction<{ field: keyof IEditableParty; value: any }>) => {
      const { field, value } = action.payload;
      if (state.party) {
        state.modifiedFields[field] = value;
        state.party[field] = value;
        state.isDirty = true;
      }
    },
    toggleEditMode: state => {
      state.editMode = !state.editMode;
    },
    setInitialized: state => {
      state.isInititalized = true;
    },
    updateCurrentlyViewingContact: (
      state,
      action: PayloadAction<{ contact: IPublicContact | null }>
    ) => {
      const { contact } = action.payload;
      state.currentlyViewingContact = contact;
    },
    updateCurrentlyViewingRsvp: (state, action: PayloadAction<{ rsvp: IPublicRsvp | null }>) => {
      const { rsvp } = action.payload;
      state.currentlyViewingRsvp = rsvp;
    },
    setGlobalLoading: (state, action: PayloadAction<{ isLoading?: boolean }>) => {
      state.globalLoading = action.payload.isLoading ?? true;
    },
    unload: () => {
      return initialState;
    },
  },
  initialState,
});
