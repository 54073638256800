import clsx from 'clsx';
import React, { FC } from 'react';

import s from './index.module.scss';

export type ColumnProps = React.ComponentPropsWithoutRef<'div'>;

export const Column: FC<ColumnProps> = ({ children, className, ...rest }) => (
  <div className={clsx(className, s.column, s.cell)} {...rest}>
    {children}
  </div>
);
