import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IEditableParty, IParty, IUser } from '../types';

export interface AppState {
  isInititalized: boolean;
  currentUser?: IUser;
  promptLogin: boolean;
  parties:
    | {
        [sid: IParty['sid']]: IEditableParty;
      }
    | undefined;
}

const initialState: AppState = {
  isInititalized: false,
  currentUser: undefined,
  promptLogin: false,
  parties: undefined,
};

export const appSlice = createSlice({
  name: 'app',
  reducers: {
    updateUser: (state, action: PayloadAction<{ user?: IUser }>) => {
      const { user } = action.payload;
      state.currentUser = user;
    },
    updateParties: (state, action: PayloadAction<{ parties: IEditableParty[] }>) => {
      const obj: { [sid: string]: IEditableParty } = {};
      action.payload.parties.forEach(party => {
        obj[party.sid] = party;
      });
      state.parties = obj;
    },
    setInitialized: state => {
      state.isInititalized = true;
    },
    setPromptLogin: state => {
      state.promptLogin = true;
    },
    unsetPromptLogin: state => {
      state.promptLogin = false;
    },
  },
  initialState,
});
