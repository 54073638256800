import { ThemeProvider as BaseThemeProvider, createTheme } from '@mui/material/styles';
import React, { FC, PropsWithChildren } from 'react';

const palette = {
  primary: { main: '#00bfa5', contrastText: '#fff' },
  secondary: { main: '#1D2026', contrastText: '#FAFAFA' },
};
const typography = {
  fontFamily: '"Open Sans", sans-serif',
};
const theme = createTheme({ palette, typography });

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  return <BaseThemeProvider theme={theme}>{children}</BaseThemeProvider>;
};
