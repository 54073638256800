import { Button, ButtonProps, IconButton, IconButtonProps, Popover } from '@mui/material';
import React, { FC, ReactNode, useState } from 'react';

type ChildrenFunction = (arg: { closeDropdown: (anything?: any) => void }) => ReactNode;
export const DropdownMenu: FC<{
  children: ChildrenFunction;
  icon?: ReactNode;
  buttonText?: ReactNode;
  ButtonProps?: ButtonProps;
  IconButtonProps?: IconButtonProps;
  className?: string;
}> = ({ children, icon, buttonText, ButtonProps, IconButtonProps, className }) => {
  const [userMenuEl, setUserMenuEl] = useState<any>(null);

  const handleUserMenu: React.MouseEventHandler<HTMLButtonElement> = event => {
    event.stopPropagation();
    setUserMenuEl(event.currentTarget);
  };
  const handleUserMenuClose = () => {
    setUserMenuEl(null);
  };

  return (
    <>
      {icon ? (
        <IconButton {...IconButtonProps} onClick={handleUserMenu} className={className}>
          {icon}
        </IconButton>
      ) : (
        <Button
          variant="contained"
          disableElevation
          onClick={handleUserMenu}
          className={className}
          {...ButtonProps}
        >
          {buttonText}
        </Button>
      )}
      <Popover
        anchorEl={userMenuEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!userMenuEl}
        onClose={handleUserMenuClose}
        elevation={1}
      >
        {children({ closeDropdown: handleUserMenuClose })}
      </Popover>
    </>
  );
};
