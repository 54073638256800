import ky, { NormalizedOptions } from 'ky';

import { getService } from '#root/store';

function setCsrf({ headers }: Request) {
  headers.set('X-CSRF-TOKEN', (window as any).csrfToken);
}

async function signOutWhenUnauthorized(
  request: Request,
  options: NormalizedOptions,
  response: Response
) {
  if (401 === response.status) {
    setTimeout(() => {
      getService('app').unloadUser();
      getService('party').refetch();
    }, 0);
  }
}

export const api = ky.extend({
  hooks: {
    beforeRequest: [setCsrf],
    afterResponse: [signOutWhenUnauthorized],
  },
});
