import { da } from 'date-fns/locale';
import { enGB } from 'date-fns/locale';

import { BaseService } from './base-service';
import { parameters } from './parameters';
import { Language } from './types';

import { ServiceGetter } from '.';

import { Keys } from '#root/translations-keys';
import { replaceAll } from '#root/utils/string';

interface ITranslations {
  [token: string]: string;
}

const usedTranslationTokens: Set<string> = new Set();
const missingTranslationTokens: Set<string> = new Set();

(window as any).usedTranslationTokens = usedTranslationTokens;
(window as any).missingTranslationTokens = missingTranslationTokens;

export class TranslationService extends BaseService {
  private language: Language = Language.DA;
  private translations: ITranslations = (window as any).translations || {};
  constructor(serviceGetter: ServiceGetter) {
    super(serviceGetter);
    // Try to get lang from URL.
    const langInUrl = location.pathname.split('/')[1];
    const availableLanguages = Object.values(Language);
    if (availableLanguages.includes(langInUrl as Language)) {
      this.language = langInUrl as Language;
    } else if (parameters.party?.language) {
      this.language = parameters.party.language as Language;
    }
  }
  currentLanguage(): Language {
    return this.language;
  }
  getLocale() {
    // @todo: When having too many languages, lets lazy load the locales.
    switch (this.language) {
      case Language.DA:
        return da;
      default:
        return enGB;
    }
  }

  translateKey = (
    getter: string | ((keys: typeof Keys) => string),
    variables: { [key: string | symbol]: string | number | undefined } = {}
  ): string => {
    if (typeof getter === 'function') {
      return this.translate(getter(Keys), undefined, variables);
    }

    return this.translate(getter, undefined, variables);
  };

  translate = (
    fallbackOrToken: string,
    token?: string,
    variables: { [key: string | symbol]: string | number | undefined } = {}
  ): string => {
    if (!token) {
      token = fallbackOrToken;
    }
    let theTranslation = this.translations[token];

    usedTranslationTokens.add(token);
    if (!theTranslation) {
      missingTranslationTokens.add(token);
      theTranslation = fallbackOrToken;
    }
    for (const key of Object.keys(variables)) {
      const value = variables[key];
      if (value === undefined) {
        theTranslation = this.removeSegment(theTranslation, key);
      } else {
        theTranslation = replaceAll(theTranslation, `\[\?${key}\]`, '');
        theTranslation = replaceAll(theTranslation, `\[${key}\?\]`, '');
        theTranslation = replaceAll(theTranslation, `\[${key}\]`, value.toString());
      }
    }
    return theTranslation;
  };

  private removeSegment(translation: string, segment: string): string {
    const regex = `\\[\\?${segment}\\].+\\[${segment}\\?\\]`;
    return translation.replace(new RegExp(regex, 'g'), '');
  }
}
