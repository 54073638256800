import { getService } from '..';
import { BaseService } from '../base-service';
import { parameters } from '../parameters';
import { IEditableParty, IHelpEntry, IUser } from '../types';

import { appSlice } from './reducer';

import { routerNavigate } from '#root/Components/ExposeRouterNavigate';
import { api } from '#root/utils/api';
export class AppService extends BaseService {
  public loadParties(): Promise<void> {
    return api
      .get('/api/parties')
      .json()
      .then((parties: IEditableParty[]) => {
        this.store.dispatch(appSlice.actions.updateParties({ parties }));
      });
  }

  public loadUser() {
    return api
      .get('/api/user')
      .json()
      .then((user: IUser) => {
        this.store.dispatch(appSlice.actions.updateUser({ user }));
        getService('party').refetch();
      })
      .catch(() => {
        // no-op
      });
  }

  public reloadUser() {
    return api
      .get('/api/user')
      .json()
      .then((user: IUser) => {
        this.store.dispatch(appSlice.actions.updateUser({ user }));
      })
      .catch(() => {
        // no-op
      });
  }

  public login(data: { email: string; password: string; remember_me?: boolean }): Promise<IUser> {
    return api
      .post('/app/login', {
        json: {
          username: data.email,
          password: data.password,
          _remember_me: data.remember_me ?? false,
        },
      })
      .json()
      .then(({ user }: { location: string; user: IUser }) => {
        // Ensure the fast loading party is forgotten, this can be without the owner.
        if (parameters.party) {
          parameters.party = undefined;
        }
        this.store.dispatch(appSlice.actions.updateUser({ user }));
        getService('party').refetch();
        return user;
      });
  }

  public register(data: {
    name: string;
    email: string;
    password: string;
    accept_terms: boolean;
    marketing_consent: boolean;
  }): Promise<void> {
    return api
      .post('/register', {
        json: {
          ...data,
          language: getService('translation').currentLanguage(),
        },
      })
      .json<IUser>()
      .then(user => {
        // Ensure the fast loading party is forgotten, this can be without the owner.
        if (parameters.party) {
          parameters.party = undefined;
        }
        this.store.dispatch(appSlice.actions.updateUser({ user }));
      });
  }

  public closePromptLogin(): void {
    this.store.dispatch(appSlice.actions.unsetPromptLogin());
  }
  public promptLogin(): Promise<IUser | undefined> {
    routerNavigate('#login');
    this.store.dispatch(appSlice.actions.setPromptLogin());
    return new Promise(resolve => {
      const interval = setInterval(() => {
        if (!this.store.getState().app.promptLogin || this.store.getState().app.currentUser) {
          clearInterval(interval);
          this.store.dispatch(appSlice.actions.unsetPromptLogin());
          resolve(this.store.getState().app.currentUser);
        }
      }, 500);
    });
  }

  public unloadUser() {
    this.store.dispatch(appSlice.actions.updateParties({ parties: [] }));
    this.store.dispatch(appSlice.actions.updateUser({ user: undefined }));
  }

  public saveUser(
    user: IUser,
    card_token?: string | undefined,
    andRedirect?: string
  ): Promise<IUser> {
    return api
      .post('/api/user', {
        json: {
          ...user,
          card_token,
        },
      })
      .json()
      .then((user: IUser) => {
        if (andRedirect) {
          window.location.replace(andRedirect);
        } else {
          this.store.dispatch(appSlice.actions.updateUser({ user }));
        }
        return user;
      });
  }

  public loadInitial(additionalLoaders?: Array<Promise<void>>): Promise<void> {
    return Promise.all([this.loadUser(), ...(additionalLoaders || [])]).then(() => {
      this.store.dispatch(appSlice.actions.setInitialized());
    });
  }

  public async submitHelpEntry(entry: IHelpEntry, language: string): Promise<void> {
    await api.post('/api/help', {
      json: {
        entry,
        language,
      },
    });
  }
  public async updatePayment(token: string): Promise<void> {
    await api.post('/api/update-payment', {
      json: {
        card_token: token,
      },
    });
  }
  public verifyEmail(): Promise<void> {
    return api.get('/api/user/begin-verify-email').json();
  }
  public beginVerifyPhone(): Promise<void> {
    return api.get(`/api/user/begin-verify-phone`).json();
  }
  public verifyPhoneCode(code: string) {
    return api
      .post(`/api/user/verify-phone-code`, { json: { code } })
      .json<IUser>()
      .then(user => {
        this.store.dispatch(appSlice.actions.updateUser({ user }));
        return user;
      });
  }
}
