import React, { FC } from 'react';

import s from './index.module.scss';

export const Logo: FC = () => {
  return (
    <h1 className={s.logo}>
      invii<span>me</span>
    </h1>
  );
};
