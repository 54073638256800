import clsx from 'clsx';
import React, { FC, PropsWithChildren } from 'react';

import { ConditionalWrapper } from '../ConditionalWrapper';

import s from './index.module.scss';

import { css } from '#root/utils/css';

export interface IProps {
  withPadding?: boolean;
  className?: string;
  small?: boolean;
  marginTop?: boolean;
  marginBottom?: boolean;
}

export const Container: FC<PropsWithChildren<IProps>> = ({
  children,
  withPadding,
  className,
  small,
  marginTop,
  marginBottom,
}) => (
  <div className={clsx(s.container, withPadding && s.padding, small && s.small, className)}>
    <ConditionalWrapper
      condition={!!(marginBottom || marginTop)}
      wrapper={children => (
        <div className={clsx(marginTop && css.spaceTopL, marginBottom && css.spaceBottomL)}>
          {children}
        </div>
      )}
    >
      {children}
    </ConditionalWrapper>
  </div>
);
