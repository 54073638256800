import clsx from 'clsx';
import React, { FC } from 'react';

import { Cell } from './Cell';
import { Column } from './Column';
import { Row } from './Row';
import s from './index.module.scss';

type TableProps = React.ComponentPropsWithoutRef<'div'>;

export interface ITableProps extends TableProps {
  template: string;
  condense?: boolean;
  bordered?: boolean;
  striped?: boolean;
}

export const Table: FC<ITableProps> = ({
  bordered,
  condense,
  striped,
  template,
  children,
  className,
  ...tableProps
}) => (
  <div
    className={clsx(
      className,
      s.table,
      striped && s.tableStriped,
      condense && s.tableCondense,
      bordered && s.tableBordered
    )}
    style={{ gridTemplateColumns: template }}
    {...tableProps}
  >
    {children}
  </div>
);

export { Cell, Row, Column };
