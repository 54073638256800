import { CircularProgress, CircularProgressProps } from '@mui/material';
import React from 'react';

const sizes = {
  small: 20,
  large: 40,
};

export const Loading: React.FC<
  CircularProgressProps & { size?: 'small' | 'large' | CircularProgressProps['size'] }
> = ({ size = 'small', ...rest }) => {
  return <CircularProgress size={sizes[size] ?? size} {...rest} />;
};
