import { BaseService } from '../base-service';
import { IEditableWish, IParty, IWish } from '../types';

import { api } from '#root/utils/api';

export class WishlistService extends BaseService {
  public async submit(partyId: IParty['id'], data: IEditableWish) {
    // Upload image if any.
    if (data.upload) {
      const formData = new FormData();
      formData.append('image', data.upload);
      console.log('Uploading wish image');
      const imageUrl = await api
        .post(`/api/party/${partyId}/wish/upload-image`, {
          body: formData,
          timeout: 30000,
        })
        .json<string>();
      console.log('Upload complete', imageUrl);
      data.image = imageUrl;
    }

    // Upload wish.
    if (data.id) {
      return await api.patch(`/api/party/${partyId}/wish/${data.id}`, { json: data }).json<IWish>();
    } else {
      return await api.post(`/api/party/${partyId}/wish`, { json: data }).json<IWish>();
    }
  }
  public async move(partyId: IParty['id'], wishId: IWish['id'], toIndex: number) {
    await api.post(`/api/party/${partyId}/wish/${wishId}/move`, { json: { toIndex } });
  }
  public async delete(partyId: IParty['id'], wishId: IWish['id']) {
    await api.delete(`/api/party/${partyId}/wish/${wishId}`);
  }
  public async reserve(wishId: IWish['id']): Promise<IWish> {
    const partyId = this.store.getState().party.party?.id;
    return api.post(`/api/party/${partyId}/wish/${wishId}/reserve`).json<IWish>();
  }
  public async unreserve(wishId: IWish['id']): Promise<IWish> {
    const partyId = this.store.getState().party.party?.id;
    return api.post(`/api/party/${partyId}/wish/${wishId}/unreserve`).json<IWish>();
  }
  public async fetch(url: string): Promise<{ image: string; title: string } | null> {
    const partyId = this.store.getState().party.party?.id;
    return api
      .post(`/api/party/${partyId}/wish/fetch`, { json: { url } })
      .json<{ image: string; title: string } | null>()
      .catch();
  }
}
