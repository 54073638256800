import { Button, ButtonProps } from '@mui/material';
import clsx from 'clsx';
import React, { FC, PropsWithChildren } from 'react';

import { Column, ColumnProps } from '../Table/Column';

import s from './SortableColumn.module.scss';

import type { OnOrderByChangeCallback, OrderBy } from '.';

import { CaretDownIcon } from '#root/utils/icons';

export interface ISortableColumnProps extends Omit<ColumnProps, 'onClick'> {
  name: string;
  orderBy: OrderBy;
  onClick: OnOrderByChangeCallback;
  Component?: React.ElementType;
}

const renderArrow = (direction: 'desc' | 'asc') => {
  return <CaretDownIcon className={clsx(s.arrow, direction === 'asc' && s.arrowFlip)} />;
};

export const SortableColumn: FC<ISortableColumnProps> = ({
  name,
  orderBy,
  onClick,
  children,
  className,
  Component = Column,
  ...rest
}) => {
  const active = orderBy && orderBy[0] === name;
  return (
    <Component
      onClick={() => onClick(name)}
      className={clsx(className, s.base, active && s.active)}
      {...rest}
    >
      {children}
      {active ? renderArrow(orderBy[1]) : null}
    </Component>
  );
};

export interface ISortableButtonProps extends Omit<ButtonProps, 'onClick'> {
  name: string;
  orderBy: OrderBy;
  onClick: OnOrderByChangeCallback;
  disabled?: boolean;
  className?: string;
}

export const SortableButton: FC<PropsWithChildren<ISortableButtonProps>> = ({
  name,
  orderBy,
  onClick,
  disabled,
  children,
  className,
  ...rest
}) => {
  const active = orderBy && orderBy[0] === name;
  return (
    <Button
      onClick={() => onClick(name)}
      className={clsx(className, s.base, active && s.active)}
      disabled={disabled}
      {...rest}
    >
      {children}
      {active ? renderArrow(orderBy[1]) : null}
    </Button>
  );
};
