import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { type IContact } from '../types';

export interface MessagesState {
  manualSelection: {
    [id: IContact['id']]: { email: boolean; text: boolean } | undefined;
  };
}

const initialState: MessagesState = {
  manualSelection: {},
};

export const messagesSlice = createSlice({
  name: 'messages',
  reducers: {
    check: (state, action: PayloadAction<{ contact: IContact }>) => {
      const { contact } = action.payload;
      state.manualSelection[contact.id] = { email: contact.receiveEmail, text: contact.receiveSms };
    },
    uncheck: (state, action: PayloadAction<{ id: IContact['id'] }>) => {
      const { id } = action.payload;
      delete state.manualSelection[id];
    },
    clearManualselection: state => {
      state.manualSelection = initialState.manualSelection;
    },
    unload: () => {
      return initialState;
    },
  },
  initialState,
});
