import { BaseService } from '../base-service';
import { partySlice } from '../party/reducer';
import { IParty, IPartySettings, ISettingsCheckedSlugResult } from '../types';

import { api } from '#root/utils/api';

export class SettingsService extends BaseService {
  public async save(partyId: IParty['id'], settings: IPartySettings) {
    const party = await api.patch(`/api/party/${partyId}`, { json: settings }).json<IParty>();

    this.store.dispatch(partySlice.actions.updateParty({ party }));
    return party;
  }

  public async checkSlug(slug: string) {
    return api.post(`/api/party/check-slug`, { json: { slug } }).json<ISettingsCheckedSlugResult>();
  }

  public async addCohost(partyId: IParty['id'], email: string) {
    const party = await api
      .post(`/api/party/${partyId}/cohost`, { json: { email } })
      .json<IParty>();

    this.store.dispatch(partySlice.actions.updateParty({ party }));
  }

  public async removeCohost(partyId: IParty['id'], id: string) {
    const party = await api.delete(`/api/party/${partyId}/cohost/${id}`).json<IParty>();

    this.store.dispatch(partySlice.actions.updateParty({ party }));
  }

  public async acceptCohost(partyId: IParty['id'], id: string, secret: string) {
    const party = await api
      .post(`/api/party/${partyId}/cohost/${id}/accept`, { json: { secret } })
      .json<IParty>();

    this.store.dispatch(partySlice.actions.updateParty({ party }));
  }
}
