import React, { FC } from 'react';

import s from './index.module.scss';

type RowProps = React.ComponentPropsWithoutRef<'div'>;

export const Row: FC<RowProps> = ({ children, ...rowProps }) => (
  <div className={s.row} {...rowProps}>
    {children}
  </div>
);
